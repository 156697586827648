import React from 'react';
import './Navbar2.css';
import { useNavigate } from 'react-router-dom';


const Logo = () => {
  const navigate = useNavigate();

  const redirectToLogo = () => {
    navigate('/');
  }

  return (
    <div onClick={redirectToLogo}>

      <img width={'100%'} height={'100%'} src="icon/ayayi2.svg" alt="Logo" />

    </div>
  );
}

// const LoginButton = () => {
//   const navigate  = useNavigate ();

//   const redirectToLogin = () => {
//     navigate ('/login');
//   }

//   return (
//     <div className='login-in' onClick={redirectToLogin}>Log in</div>
//   );
// }

// const AboutButton = () => {
//   const [isHover2, setIsHover2] = useState(false);

//   const handleMouseMove = () => {
//     setIsHover2(true);
//   };

//   const handleMouseOut = () => {
//     setIsHover2(false);
//   };
//   const navigate  = useNavigate ();

//   const redirectToAbout = () => {
//     navigate ('/about');
//   }

//   const classes = isHover2 ? 'about-in' : '';

//   return (
//     <div className={classes}
//         onClick={redirectToAbout}
//         onMouseMove={handleMouseMove}
//         onMouseOut={handleMouseOut}
//         >About Us</div>
//   );
// };

// const Store = () => {
//   const [isHover, setIsHover] = useState(true);

//   const handleMouseMove = () => {
//     setIsHover(false);
//   };

//   const handleMouseOut = () => {
//     setIsHover(true);
//   };

//   const handleClick = () => {
//     window.open('https://www.baidu.com');
//   };

//   return (
//     <div className="store" onClick={handleClick}>
//       <img
//         width={26}
//         height={26}
//         src={isHover ? 'icon/store.png' : 'icon/store3.png'}
//         alt="store"
//         onMouseMove={handleMouseMove}
//         onMouseOut={handleMouseOut}
//       />
//     </div>
//   );
//  };

const Navbar = ({ className }) => {
  return (
    <div className={className}>
      <div className="logo-nv2">
        <Logo />
      </div>
      {/* <div className='right'> 
        <div className="about">
          <AboutButton/>
        </div>
        <div className="store">
            <Store/>
        </div>
        <div className="login">
          <LoginButton/>
        </div>
     </div> */}

    </div>
  );
};

export default Navbar;