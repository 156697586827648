
import './User.css';
import Navbar1 from '../components/Navbar1';
import Footer2 from '../components/Footer2';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount, useEnsName } from 'wagmi'
import ImageSlider from '../components/ImgSlider'
import { getWalletBind, getAssetAllList } from '../api';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import ayayiStore from '../mobx/index'


const User = observer(() => {

  const channel = localStorage.getItem('channel')
  const { open } = useWeb3Modal()
  const { address, isConnected } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const nickname = ayayiStore.nickname.get()?.toUpperCase() ?? localStorage.getItem('nickname')?.toUpperCase() ?? 'none';
  const avatar = ayayiStore.avatar.get() ?? localStorage.getItem('avatar') ?? 'icon/avatar.png';

  const userId = ayayiStore.userId.get() ?? localStorage.getItem('userId') ?? 'none';

  useEffect(() => {
    if (isConnected) {
      ayayiStore.address.set(address)

      ayayiStore.ensName.set(ensName)
      getWalletBind({ 'addr': address }).then((res) => {
        if (res.data.code === 200) {
          getAssetAllList({
            page: 1,
            pageSize: 100,
            wallet: address
          }).then((r) => {
            const nftImgs = r.data.data.list.map(item => item.imageUrl);
            ayayiStore.nftImgs.replace(nftImgs)
          });
        }
      });
    } else {
      ayayiStore.address.set();
      ayayiStore.ensName.set();
      ayayiStore.nftImgs.clear();
    }
  }, [address, isConnected, ensName]);

  const Profile = () => {
    const doNFT = () => {
      open();
    };

    return (
      <div onClick={() => doNFT()}>
        {isConnected ? (
          <div className='wallet-button' >
            {ayayiStore.ensName.get() ?? address}
          </div>
        ) : (
          <div className='wallet-button'>
            CONNECT A WALLET
          </div>
        )}
      </div>
    );
  };
  return (
    <div className="User">
      <header className="User-header">
        <div className='navbar'>
          <Navbar1 className="navbar1" />
        </div>
      </header>

      <div className="User-body">
        <div className="body-top">
          <div className='top-all'>
            <div className='avatar'>
              <img width={'100%'} height={'100%'} src={avatar} alt="avatar" />
            </div>
            <div className='info'>
              <div className='info-name'>{nickname}</div>
              <div className='info-edit'>
                {/* EDIT */}
              </div>
            </div>
            <div className='info-id'>
              <div>ID: {userId}</div>
            </div>
          </div>
        </div>
        <div className="body-center">
          <div className="wallet-top">
            <div className="wallet-top-left">
              <div className="wallet-top-left-icon">
                <img width={'100%'} height={'100%'} src="icon/wallet.svg" alt="icon" />
              </div>
              <div className="wallet-top-left-font">MY WALLET</div>
            </div>

            <div className="wallet-top-right">
              {/* <img width={'100%'} height={'100%'} src="icon/question.svg" alt="icon" /> */}
            </div>
          </div>

          <div className="wallet-bottom">
            <div className="wallet-bottom-left">
              <div className="wallet-bottom-left-left">
                <div className='wallet-info-title'>
                  <div>See Your Balance And Unlock</div>
                  <div>All Features</div>
                </div>
                <div className='wallet-connect-button'>
                  <Profile />
                </div>
              </div>
              {/* {
                channel === 'PC' ?
                  <div className="wallet-bottom-left-right">
                    <div className='wallet-bottom-left-right-title'>
                      <div>Connect Your Wallet To Unlock The Full
                        The Sandbow Metaverse Experience</div>
                    </div>

                    <div className='wallet-bottom-left-right-font'>
                      <div>· Earn More Sand By Staking</div>
                      <div>· Play With External Avatars</div>
                      <div>· Buy And Sell Nfts In The Marketplace</div>
                      <div>· Claim Rewards</div>
                    </div>
                  </div>
                  : ''} */}
            </div>
            <div className="wallet-bottom-right">
              <img width={'100%'} height={'100%'} src="icon/icon3.svg" alt="icon" />
            </div>
          </div>

        </div>
        <div className="body-bottom">
          <div className="nft-top">
            <div className="nft-top-left">
              <div className="nft-top-left-icon">
                <img width={'100%'} height={'100%'} src="icon/icon-nft.svg" alt="icon" />
              </div>
              <div className="nft-top-left-font">NFTs</div>
            </div>

            <div className="nft-top-right">
              {/* <img width={'100%'} height={'100%'} src="icon/icon-add.svg" alt="icon" /> */}
            </div>
          </div>
          <div className="nft-mid">
            ADVENTURER
          </div>
          <div className='nft-bottom'>
            <div className='nft-show'>
              <ImageSlider />
            </div>
          </div>
        </div>
      </div>

      <footer>
        <div className='footer'>
          <Footer2 className="footer2" />
        </div>
      </footer>
    </div>

  );
})

export default User;
