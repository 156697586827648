import { initializeApp } from 'firebase/app';
import { getAuth, OAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult } from 'firebase/auth';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { thirdPartyLogin, getUserDetail } from '../api';
import { observer } from 'mobx-react-lite';
import ayayiStore from '../mobx/index'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const SignApple = observer(() => {
    const navigate = useNavigate();
    const firebaseConfig = {
        apiKey: "AIzaSyB2ijGNHXYycJ1Ke4M_DYHogaBjMv83BFc",
        authDomain: "ayayi-7b084.firebaseapp.com",
        projectId: "ayayi-7b084",
        storageBucket: "ayayi-7b084.appspot.com",
        messagingSenderId: "863706056748",
        appId: "1:863706056748:web:6a48f2c4b4a7876ee26989",
        measurementId: "G-1B2K1R9WS1"
    };

    // const channel = localStorage.getItem('channel')

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const provider = new OAuthProvider('apple.com');

    useEffect(() => {
        getRedirectResult(auth)
            .then((result) => {
                // const credential = GoogleAuthProvider.credentialFromResult(result);
                // // // eslint-disable-next-line no-unused-vars
                // const token = credential.accessToken;
                const user = result.user;
                toast('loading...', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                thirdPartyLogin({
                    "userId": user.uid,
                    "platform": 'Apple',
                    "avatar": user.photoURL,
                    "nickname": user.displayName,
                    "token": user.accessToken
                }).then((res) => {
                    if (res.data.code === 200) {
                        localStorage.setItem('token', res.data.data.authentication);
                        getUserDetail({}).then((r) => {
                            if (r.data.code === 200) {
                                if (r.data.data.avatar !== '') {
                                    ayayiStore.avatar.set(r.data.data.avatar)
                                    localStorage.setItem('avatar', r.data.data.avatar);
                                }
                                ayayiStore.nickname.set(r.data.data.nickname)
                                ayayiStore.userId.set(r.data.data.userId)
                                navigate('/user');

                                localStorage.setItem('nickname', r.data.data.nickname);
                                localStorage.setItem('userId', r.data.data.userId);
                            }
                        })

                    }
                }).catch((err) => {
                    console.log(err)
                })

            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                const credential = OAuthProvider.credentialFromError(error);
                // console.log(errorCode, errorMessage, credential)
            })
    }, [auth, navigate]);
    const signinApple = () => {


        if (localStorage.getItem('channel') === 'PC') {
            signInWithPopup(auth, provider)
                .then((result) => {
                    const user = result.user;
                    toast('loading...', {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    thirdPartyLogin({
                        "userId": user.uid,
                        "platform": 'Apple',
                        "avatar": user.photoURL,
                        "nickname": user.displayName,
                        "token": user.accessToken
                    }).then((res) => {
                        if (res.data.code === 200) {
                            localStorage.setItem('token', res.data.data.authentication);
                            getUserDetail({}).then((r) => {
                                if (r.data.code === 200) {
                                    console.log(r.data.data)
                                    if (r.data.data.avatar !== '') {
                                        ayayiStore.avatar.set(r.data.data.avatar)
                                        localStorage.setItem('avatar', r.data.data.avatar);
                                    }
                                    localStorage.setItem('nickname', r.data.data.nickname);
                                    // localStorage.setItem('avatar', r.data.data.avatar);
                                    localStorage.setItem('userId', r.data.data.userId);
                                    // localStorage.setItem('walletAddrs', r.data.data.walletAddrs);

                                    ayayiStore.nickname.set(r.data.data.nickname)
                                    // ayayiStore.avatar.set(r.data.data.avatar)
                                    ayayiStore.userId.set(r.data.data.userId)
                                    navigate('/user');
                                }
                            })

                        }
                    }).catch((err) => {
                        console.log(err)
                    })
                }).catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(errorCode, errorMessage)
                });
        }
        else {
            signInWithRedirect(auth, provider)
        }
    }
    return (<>
        <img className='lg' onClick={() => signinApple()} width={'100%'} height={'100%'} src="icon/apple.svg" alt="apple" />
        <ToastContainer />
    </>

    )
})

export default SignApple

