
import './About.css';
import Navbar1 from '../components/Navbar1';
import Footer1 from '../components/Footer1';

function About() {

  const channel = localStorage.getItem('channel')
  return (
    <div className="About">
      <header className="About-header">
        <div className='About-navbar'>
          <Navbar1 className="navbar1" />
        </div>
      </header>
      <div className='about-body'>
        <div className='about-page1'>
          <div className='about-page1-font'>ABOUT AYAYI®</div>
        </div>
        <div className='about-page2'>
          <div className='about-page2-in'>
            <div className='about-page2-in-left'>
              {
                channel === 'PC' ?
                  <img src="other/about-img1.jpg" alt="img1" />
                  :
                  <img src="other/about-img10.jpg" alt="img1" />

              }
            </div>
            <div className='about-page2-in-right'>
              <div className='about-page2-in-right-top'>
                <div className='about-page2-in-right-top-img'>
                  <img width={'100%'} height={'100%'} src="icon/ayayi.svg" alt="ayayi" />
                </div>
              </div>

              <div className='about-page2-in-right-mid'>
                <div className='mid-p'><div className='mid-p-div'>Where Multiple Dimensions Converge, </div></div>
                <div className='mid-p'><div className='mid-p-div'>Dreams And Reality Synergize</div></div>

              </div>

              <div className='about-page2-in-right-bottom'>
                Positioned at the crossroads of the spatial computing era, the wave of integration between virtual and real is sweeping through all aspects of individual lives. AYAYI® has revolutionized its product foundation, merging the characteristics of the "virtual" and the "real" to create a unique PHYGITAL aesthetic style and product form. Our aim is to provide every forward-thinking young individual in reality with a cultural paradise for realizing their dreams and personal pursuits, constructing a tangible, miraculous, and creative "Realistic Utopia."
              </div>
            </div>
          </div>
        </div>
        {
          channel === 'PC' ?
            <div className='about-page3'></div>
            : ''}
        <div className='about-page4'>
          <div className='about-page4-in'>
            <div className='about-page4-in-top'>
              <div className='about-page4-in-top-left'>
                <div className='about-page4-in-top-left-title'>
                  <div>THE WORLD'S PREMIER HYPER-REALISTIC </div>
                  <div>DIGITAL PERSONA</div>
                </div>
                <div className='about-page4-in-top-left-title2'>
                  <div className='about-page4-in-top-left-title2-mdash'></div>&nbsp;FROM&nbsp;AYAYI&nbsp;TO&nbsp;AYAYI®
                </div>
                <div className='about-page4-in-top-left-font'>
                  <div>In 2021, China's first hyper-realistic digital persona, AYAYI, was born. Its debut</div>
                  <div>created a sensation, attracting hundreds of thousands of fans overnight.</div>
                </div>
              </div>
              {
                channel === 'PC' ?
                  <div className='about-page4-in-top-right'>
                    <img width={'100%'} height={'100%'} src="icon/about-icon.svg" alt="icon" />
                  </div>
                  : ''}
            </div>
            <div className='about-page4-in-mid'>
              <img width={'100%'} height={'100%'} src="other/about-img7.jpg" alt="img7" />

            </div>
            <div className='about-page4-in-bottom'>
              <div>At that time, the concept of the metaverse was not yet widespread. It was AYAYI that brought the pinnacle of silicon-based lifeform aesthetics to the public eye, acting as a bridge with its mission to link reality and virtuality. Throughout its commercial evolution, AYAYI collaborated with diverse brands, renowned artists, and innovative IPs, embarking on a journey of digital development.</div>

              <div style={{
                marginTop: '10px',
              }}>Reflecting on feedback from the market and the public, AYAYI realized that modern science and technology require a content ecosystem, and digital life in the virtual world needs to be grounded in real-life scenarios to be meaningful.</div>
              <div style={{
                marginTop: '10px',
              }}>Consequently, AYAYI was eager to introduce the "PHYGITAL" concept into the real world, combining digital elements with the physical attributes of the real world to create a new dimension that is both realistic and brimming with infinite possibilities.</div>
              <div style={{
                marginTop: '10px',
              }}>Thus, AYAYI®, the namesake original brand was born.</div>
              <div style={{
                marginTop: '10px',
              }}>Centering on PHYGITAL, AYAYI® aspires to offer the younger generation an ideal world filled with innovation, personalization, and artistry, a "Realistic Utopia" where the boundaries between reality and virtuality blur, making dreams possible.</div>

            </div>
          </div>
        </div>
        {
          channel === 'PC' ?
            <div className='about-page5-pc'>
              <div className='about-page5-img-pc'>
                <img width={'100%'} height={'100%'} src="other/about-img5.jpg" alt="img1" />

              </div>
              <div className='about-page5-img-pc'>
                <img width={'100%'} height={'100%'} src="other/about-img4.jpg" alt="img1" />

              </div>
              <div className='about-page5-img-pc'>
                <img width={'100%'} height={'100%'} src="other/about-img3.jpg" alt="img1" />

              </div>
              <div className='about-page5-img-pc'>
                <img width={'100%'} height={'100%'} src="other/about-img2.jpg" alt="img1" />

              </div>

            </div>
            :
            <div className='about-page5'>
              <div className='about-page5-img'>
                <img width={'100%'} height={'100%'} src="other/about-img5.jpg" alt="img1" />

              </div>
              <div className='about-page5-img'>
                <img width={'100%'} height={'100%'} src="other/about-img8.jpg" alt="img1" />

              </div>
              <div className='about-page5-img'>
                <img width={'100%'} height={'100%'} src="other/about-img9.jpg" alt="img1" />

              </div>
              <div className='about-page5-img'>
                <img width={'100%'} height={'100%'} src="other/about-img2.jpg" alt="img1" />

              </div>

            </div>
        }
      </div>
      <footer>
        <div className='about-footer'>
          <Footer1 className="footer1" />
        </div>
      </footer>
    </div>
  );
}

export default About;
