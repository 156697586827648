
import './More.css';
import Navbar1 from '../components/Navbar1';
import Footer1 from '../components/Footer1';

function More() {

  const channel = localStorage.getItem('channel')
  return (
    <div className="More">
      <header className="More-header">
        <div className='More-navbar'>
          <Navbar1 className="navbar1" />
        </div>
      </header>
      <div className='more-body'>
        <div className='more-page1'>
          <div className='more-page1-in'>
            <div className='more-page1-font'>
              <div className='more-page1-font-title'>
                <div>REDEFINING THE</div>
                <div>PHYGITAL PRODUCT FORM</div>
              </div>

              <div className='more-page1-font-font'>
                Merging digital aesthetics with physical craftsmanship, we embody the integrated design philosophy of virtual and real, conveying a unique PHYGITAL visual concept. The innovative application of digital assets and the deep exploration of AIGC herald a future of design enriched with deeper layers of diversity and personalized experiences.
              </div>
            </div>
            <div className='more-page1-img'>
              <img width={'100%'} height={'100%'} src="other/more-img1.jpg" alt="img1" />
            </div>
          </div>
        </div>
        <div className='more-page2'>
        </div>
        <div className='more-page5'>
          <div className='more-page5-in'>
            <div className='more-in-add-top'>
              <div className='more-in-add-top-1'></div>
              <div className='more-in-add-top-2'>VISUAL</div>
              <div className='more-in-add-top-3'></div>
            </div>
            <div className='more-page5-in-title'>
              AESTHETICS OF VIRTUAL-REAL INTEGRATION
            </div>
            <div className='more-page5-in-img1'>
              <div className='more-page5-in-img1-1'>
                <img src="other/more-img3.jpg" alt="img3" />
              </div>
              <div className='more-page5-in-img1-2'>
                <img src="other/more-img4.jpg" alt="img4" />
              </div>
            </div>
            <div className='more-page5-in-font1'>
              In product design, the innovative concept of integrating virtual and real expands the horizons of visual expression. Digital language becomes an extension of physical products, naturally blending to create an equilibrium of virtual-real aesthetics, giving birth to visual wonders that are both real and surreal.
            </div>
            <div className='more-page5-in-img2'>
              <img src="other/more-img5.jpg" alt="img5" />
            </div>
            <div className='more-page5-in-font2'>
              The boundaries between fantasy and reality are dismantled, freeing creativity from physical constraints and endowing aesthetics with new dimensions.
            </div>
            <div className='more-in-add-page5-img1'>
              <img width={'100%'} height={'100%'} src="other/more-img6.jpg" alt="img6" />
            </div>
            <div className='more-in-add-top'>
              <div className='more-in-add-top-1'></div>
              <div className='more-in-add-top-2'>INTERACTION</div>
              <div className='more-in-add-top-3'></div>
            </div>
            <div className='more-page5-in-title'>
              A MULTIDIMENSIONAL SENSORY EXPERIENCE
            </div>
            <div className='more-page5-in-font1'>
              AYAYI® is committed to providing users with a multidimensional experience of its products. Whether it's clothing, furniture, or lifestyle products, you can access corresponding digital assets by simply touching the exclusive NFC chip embedded in them.
            </div>

            <div className='more-page5-in-img2'>
              <img src="other/more-img7.jpg" alt="img7" />
            </div>
            <div className='more-page5-in-font2'>
              The application of AR and MR technologies maps the limitless inspiration of the digital world onto real-world scenarios. While satisfying sensory pleasures, it also opens up new possibilities for digital social interactions.
            </div>
          </div>
        </div>
        <div className='more-page4'>
        </div>
        <div className='more-page3'>
          <div className='more-page5-in'>
            <div className='more-in-add-top'>
              <div className='more-in-add-top-1'></div>
              <div className='more-in-add-top-2'>CREATION</div>
              <div className='more-in-add-top-3'></div>
            </div>
            <div className='more-page5-in-title'>
              AI-GENERATED CUSTOMIZATION
            </div>
            <div className='more-page5-in-font1'>
              From the outset of the brand, AI technology has played a pivotal role in this adventurous endeavor. With its exceptional capabilities in deep learning and autonomous innovation, AIGC has not only delved into and expanded product design concepts but also broadened the horizons of imagination. Each product is endowed with a unique, one-of-a-kind digital identity.
            </div>
            <div className='more-in-add-imgs'>
              <div className='more-in-add-img'>
                <img width={'100%'} height={'100%'} src="other/more-img12.jpg" alt="img5" />
              </div>
              <div className='more-in-add-img'>
                <img width={'100%'} height={'100%'} src="other/more-img11.jpg" alt="img5" />
              </div>
              <div className='more-in-add-img'>
                <img width={'100%'} height={'100%'} src="other/more-img9.jpg" alt="img5" />
              </div>
              <div className='more-in-add-img'>
                <img width={'100%'} height={'100%'} src="other/more-img10.jpg" alt="img5" />
              </div>
            </div>
            {
              channel === 'PC' ?
                <div className='more-page5-in-font1'>
                  <div>
                    In the future, we plan to open up our initial AI creation tool, “Prompt,” to our users, enabling them to directly participate in the creation of virtual assets.
                  </div>
                  <div>Through simple operations and command inputs, users can generate their own personalized AVATARS, achieving a
                    collaborative creation with the brand's design.</div>
                </div>
                : ''}

            <div className='more-in-add-top'>
              <div className='more-in-add-top-1'></div>
              <div className='more-in-add-top-2'>ATTITUDE</div>
              <div className='more-in-add-top-3'></div>
            </div>
            <div className='more-page5-in-title'>
              BUILDING REALISTIC UTOPIA
            </div>
            <div className='more-page5-in-font1'>
              AYAYI®'s pursuit goes beyond aesthetic innovation; it is a commitment to building a "Realistic Utopia" – a cultural paradise where dreams and reality coexist and intermingle. We encourage the new generation of young people to express themselves, unleash their creativity, and become artists of their own lives.
            </div>
            <div className='more-in-add-page3-img1'>
              <img width={'100%'} height={'100%'} src="other/more-img13.jpg" alt="img13" />
            </div>
            <div className='more-page5-in-font1'>
              This is a belief that deeply rooted in individual life, an unwavering pursuit of a vibrant, innovative, and diverse future filled with possibilities.
            </div>

          </div>
        </div>
        <div className='more-page7'>
          <div className='more-page7-in'>
            <div className='more-page7-in-title'>
              GET YOUR OWN AYAYI® DIGITAL ASSETS WITHIN CLICKS
            </div>
            {
              channel === 'PC' ?
                <div className='more-page7-in-part'>
                  <div className='more-page7-in-group'>
                    <div className='more-page7-in-group-img'>
                      <img width={'100%'} height={'100%'} src="icon/step1.svg" alt="step" />

                    </div>
                    <div className='more-page7-in-group-code'>1-1</div>
                    <div className='more-page7-in-group-title'>Download AYAYI® APP</div>
                    <div className='more-page7-in-group-font'>
                      Download " AYAYI® "on APP store.
                    </div>
                    <div className='more-page7-in-group-font2'>
                      *Note: Currently, only the IOS system is supported.
                    </div>
                  </div>

                  <div className='more-page7-in-group'>
                    <div className='more-page7-in-group-img'>
                      <img width={'100%'} height={'100%'} src="icon/step2.svg" alt="step" />

                    </div>
                    <div className='more-page7-in-group-code'>1-2</div>
                    <div className='more-page7-in-group-title'>Register And Log In</div>
                    <div className='more-page7-in-group-font'>
                      Enter your personal information to register and log in.
                    </div>
                  </div>


                  <div className='more-page7-in-group'>
                    <div className='more-page7-in-group-img'>
                      <img width={'100%'} height={'100%'} src="icon/step3.svg" alt="step" />

                    </div>
                    <div className='more-page7-in-group-code'>1-3</div>
                    <div className='more-page7-in-group-title'>Touch NFC</div>
                    <div className='more-page7-in-group-font'>
                      Hold your phone close to the clothing tag and follow the instructions to receive your AYAYI®digital assets.
                    </div>

                    <div className='more-page7-in-group-font2'>
                      *Note: If unable to recognize it, please update the software version in a timely manner, or open your phone:
                      "Settings" - "General" - "NFC" to check if this feature is enabled.
                    </div>
                  </div>


                  <div className='more-page7-in-group'>
                    <div className='more-page7-in-group-img'>
                      <img width={'100%'} height={'100%'} src="icon/step4.svg" alt="step" />

                    </div>
                    <div className='more-page7-in-group-code'>1-4</div>
                    <div className='more-page7-in-group-title'>Real-Time AR Experience</div>
                    <div className='more-page7-in-group-font'>
                      Open the AYAYI® APP camera function, experience the Real-Time AR digital asset wearing effect, and view your personal digital assets in the AYAYI® APP - "Wallet".
                    </div>
                  </div>

                </div>
                :
                <div className='more-page7-in-part'>
                  <div className='more-page7-in-group'>
                    <div className='more-page7-in-group-img'>
                      <img width={'100%'} height={'100%'} src="icon/step1.svg" alt="step" />
                    </div>
                    <div className='more-page7-in-group-code'>1-1</div>
                    <div>
                      <div className='more-page7-in-group-title'>Download AYAYI® APP</div>
                      <div className='more-page7-in-group-font'>
                        Scan the QR code below to download
                      </div>
                      <div className='more-page7-in-group-font2'>
                        *Note: Currently, only the IOS system is supported.
                      </div>
                    </div>

                  </div>

                  <div className='more-page7-in-group'>
                    <div className='more-page7-in-group-img'>
                      <img width={'100%'} height={'100%'} src="icon/step2.svg" alt="step" />
                    </div>
                    <div className='more-page7-in-group-code'>1-2</div>
                    <div>
                      <div className='more-page7-in-group-title'>Register And Log In</div>
                      <div className='more-page7-in-group-font'>
                        When prompted, enter your personal information to register and log in.
                      </div>
                    </div>

                  </div>


                  <div className='more-page7-in-group'>
                    <div className='more-page7-in-group-img'>
                      <img width={'100%'} height={'100%'} src="icon/step3.svg" alt="step" />
                    </div>
                    <div className='more-page7-in-group-code'>1-3</div>
                    <div>
                      <div className='more-page7-in-group-title'>Touch NFC</div>
                      <div className='more-page7-in-group-font'>
                        Hold your phone close to the clothing tag and follow the instructions to receive your AYAYI® digital assets.
                      </div>

                      <div className='more-page7-in-group-font2'>
                        *Note: If unable to recognize it, please update the software version in a timely manner, or open your phone:
                        "Settings" - "General" - "NFC" to check if this feature is enabled.
                      </div>
                    </div>

                  </div>


                  <div className='more-page7-in-group'>
                    <div className='more-page7-in-group-img'>
                      <img width={'100%'} height={'100%'} src="icon/step4.svg" alt="step" />
                    </div>
                    <div className='more-page7-in-group-code'>1-4</div>
                    <div>
                      <div className='more-page7-in-group-title'>Real-Time AR Experience</div>
                      <div className='more-page7-in-group-font'>
                        Open the AYAYI® APP camera function, experience the Real-Time AR digital asset wearing effect, and view your personal digital assets in the AYAYI® APP - "Wallet".
                      </div>
                    </div>

                  </div>



                </div>
            }
            <div className='more-page7-in-down'>
              Stay tuned for your AYAYI® digital assets that will give you access to more exclusive benefits in our brand's future plans.
            </div>

          </div>
        </div>
      </div>
      <footer>
        <div className='more-footer'>
          <Footer1 className="footer1" />
        </div>
      </footer>
    </div>
  );
}

export default More;
