// import React, { useState } from 'react';
import './Footer2.css';
// import { useNavigate } from 'react-router-dom';


const Footer = ({ className }) => {
    return (
        <div className={className}>
            <div className='footer2-right'>
                <span>Produced by AYAYI® © 2023</span>
                <a href='https://beian.miit.gov.cn/'>沪ICP备2021036297号-3</a>
            </div>
        </div>
    );
};

export default Footer;