import React, { useState, useEffect } from 'react';

import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Home from './home/Home';
import Login from './login/Login';
import About from './about/About';
import User from './user/User';
import More from './more/More';
import './App.css';

import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'

import { WagmiConfig } from 'wagmi'
import { arbitrum, mainnet } from 'viem/chains'


const projectId = 'bcf0071d0f0116e7c566ba7743423480'
const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}
const chains = [mainnet, arbitrum]
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })
createWeb3Modal({ wagmiConfig, projectId, chains })


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {

  // eslint-disable-next-line no-unused-vars
  const [channel, setChannel] = useState('');
  useEffect(() => {
    // 判断channel的值，并储存到localStorage中
    const ua = navigator.userAgent;
    const isWindowsPhone = /(?:Windows Phone)/.test(ua);
    const isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone;
    const isAndroid = /(?:Android)/.test(ua);
    const isFireFox = /(?:Firefox)/.test(ua);
    // const isChrome = /(?:Chrome|CriOS)/.test(ua);
    const isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua));
    const isPhone = /(?:iPhone)/.test(ua) && !isTablet;
    const isPc = !isPhone && !isAndroid && !isSymbian;

    let channelValue = '';
    if (isPc || isTablet) {
      channelValue = 'PC';
    }
    // else if (isTablet) {
    //   channelValue = 'Tablet';
    // } 
    else if (isAndroid || isPhone) {
      channelValue = 'Phone';
    }

    setChannel(channelValue);
    localStorage.setItem('channel', channelValue);
  }, []);

  return (
    <WagmiConfig config={wagmiConfig}>
      <div className="App">

        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/about" element={<About />} />
            <Route path="/user" element={<User />} />
            <Route path="/more" element={<More />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </div>
    </WagmiConfig>
  );
}

export default App;
