import React from 'react';
import './Footer1.css';

import { useNavigate } from 'react-router-dom';

const Footer = ({ className }) => {

    const channel = localStorage.getItem('channel')
    const navigate = useNavigate();
    const toAbout = () => {
        navigate('/about');
    }
    const toHome = () => {
        navigate('/');
    }
    return (
        <div className={className}>
            {
                channel === 'PC' ?
                    <div className='footer-in-pc'>
                        <div className='footer-left-pc'>
                            <div className='footer-left-top-pc' onClick={toHome}>
                                <img width={'100%'} height={'100%'} src="icon/ayayi2.svg" alt="Logo" />
                            </div>
                            <div className='footer-left-mid-pc'>
                                <div className='text-pc'>
                                    <div className='text-title-pc'>Products</div>
                                    <div className='text-font-pc'><a href="https://ayayi.shop/">Shopify</a></div>
                                </div>
                                <div className='text-pc'>
                                    <div className='text-title-pc'>Support</div>
                                    <div className='text-font-pc'><a href='mailto:contact@ayayi.space'>Contact Us</a></div>
                                </div>
                                <div className='text-pc'>
                                    <div className='text-title-pc'>Company</div>
                                    <div className='text-font-pc' onClick={toAbout}>About Us</div>
                                </div>
                                <div className='text-pc'>
                                    <div className='text-title-pc'>Legal</div>
                                    <div className='text-font-pc'><a target="_blank" href="/privacy_term/AYAYI%20Website_privacy.html">Privacy Notice</a></div>
                                    <div className='text-font-pc'><a target="_blank" href="/privacy_term/AYAYI%20Website_term.html">Term of Service</a></div>
                                </div>
                            </div>

                        </div>
                        <div className='footer-right-pc'>
                            <span>Produced by AYAYI® © 2023</span>
                            <a href='https://beian.miit.gov.cn/'>沪ICP备2021036297号-3</a>
                        </div>
                    </div>
                    :
                    <div className='footer-in'>
                        <div className='footer-left'>
                            <div className='footer-left-top'>
                                <img width={'100%'} height={'100%'} src="icon/ayayi2.svg" alt="Logo" />
                            </div>
                            <div className='footer-left-mid'>
                                <div className='text'>
                                    <div className='text-title'>Products</div>
                                    <div className='text-font'><a href="https://www.example.com">Shopify</a></div>
                                </div>
                                <div className='text'>
                                    <div className='text-title'>Support</div>
                                    <div className='text-font'>Contact Us</div>
                                </div>
                                <div className='text'>
                                    <div className='text-title'>Company</div>
                                    <div className='text-font' onClick={toAbout}>About Us</div>
                                </div>
                                <div className='text'>
                                    <div className='text-title'>Legal</div>
                                    <div className='text-font'><a href="https://ayayi.space/privacy_term/AYAYI%20Website_privacy.html">Privacy Notice</a></div>
                                    <div className='text-font'><a href="https://ayayi.space/privacy_term/AYAYI%20Website_term.html">Term of Service</a></div>
                                </div>
                            </div>

                        </div>
                        <div className='footer-right'>
                            <span>Produced by AYAYI® © 2023</span>
                            <a href='https://beian.miit.gov.cn/'>沪ICP备2021036297号-3</a>
                        </div>

                    </div>
            }
        </div>
    );
};

export default Footer;