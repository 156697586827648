import React, { useState, useEffect, useRef } from 'react';
import './Navbar1.css';
import isLoggedIn from '../utils/global';
import { useNavigate } from 'react-router-dom';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount, useEnsName, useDisconnect } from 'wagmi'
import { getWalletBind, getAssetAllList } from '../api';
import { observer } from 'mobx-react-lite';
import ayayiStore from '../mobx/index'


const channel = localStorage.getItem('channel')
const Logo = () => {
  const navigate = useNavigate();

  const redirectToLogo = () => {
    navigate('/');
  }

  return (
    <div onClick={redirectToLogo}>

      <img width={'100%'} height={'100%'} src="icon/ayayi2.svg" alt="Logo" />

    </div>
  );
}

const nickname = ayayiStore.nickname.get() ?? localStorage.getItem('nickname') ?? 'none';
const avatar = ayayiStore.avatar.get() ?? localStorage.getItem('avatar') ?? 'icon/avatar.png';

// console.log(ayayiStore.avatar.get(), '3', localStorage.getItem('avatar'))
// const userId = ayayiStore.userId.get() || 'none';

const LoginButton = observer(() => {

  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const [storedToken, setStoredToken] = useState(false)
  useEffect(() => {
    // 检查localStorage中是否存在token
    // const token = localStorage.getItem('token');
    if (isLoggedIn()) {
      setStoredToken(true);
    } else {
      setStoredToken(false);
    }
  }, [storedToken]);
  const redirectToLogin = () => {
    navigate('/login');
  }




  const { open } = useWeb3Modal()
  const { address, isConnected } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const { disconnect } = useDisconnect()

  useEffect(() => {
    if (isConnected) {
      ayayiStore.address.set(address)
      ayayiStore.ensName.set(ensName)
      getWalletBind({ 'addr': address }).then((res) => {
        if (res.data.code === 200) {
          getAssetAllList({
            page: 1,
            pageSize: 6,
            wallet: address
          }).then((r) => {
            const nftImgs = r.data.data.list.map(item => item.imageUrl);
            ayayiStore.nftImgs.replace(nftImgs)
          });
        }
      });
    } else {
      ayayiStore.address.set();
      ayayiStore.ensName.set();
      ayayiStore.nftImgs.clear();
    }
  }, [address, isConnected, ensName]);

  const openWallet = () => {
    open()
  }

  const toUser = () => {
    navigate('/user');
  }

  const logOut = () => {
    setStoredToken(false);
    disconnect();
    localStorage.removeItem('token');
    localStorage.removeItem('nickname');
    localStorage.removeItem('avatar');
    localStorage.removeItem('userId');
    ayayiStore.nickname.set();
    ayayiStore.userId.set();
    ayayiStore.avatar.set();
    ayayiStore.address.set();
    ayayiStore.ensName.set();
    ayayiStore.nftImgs.clear();
    navigate('/');
  }

  // const clickCountRef = useRef(0);
  // const handleTouchStart = () => {
  //   clickCountRef.current += 1;

  //   if (clickCountRef.current === 2) {
  //     setStoredToken(false);
  //     disconnect();
  //     localStorage.removeItem('token');
  //     localStorage.removeItem('nickname');
  //     localStorage.removeItem('avatar');
  //     localStorage.removeItem('userId');
  //     ayayiStore.nickname.set();
  //     ayayiStore.userId.set();
  //     ayayiStore.avatar.set();
  //     ayayiStore.address.set();
  //     ayayiStore.ensName.set();
  //     ayayiStore.nftImgs.clear();
  //     clickCountRef.current = 0;
  //   } else {
  //     setTimeout(() => {
  //       clickCountRef.current = 0;
  //     }, 300);
  //   }
  // };


  return (
    <div>
      {
        storedToken ? <>
          <div className='logined'>
            <div className='logined-wallet' onClick={openWallet}>
              <div>{ayayiStore.address.get() ?? 'connect a wallet'}
              </div>
            </div>

            {/* // <div className='logined-avatar'  onDoubleClick={logOut} onTouchStart={handleTouchStart}> */}
            <div className='logined-avatar' onClick={() => setShow(!show)}>
              <img width={'100%'} height={'100%'} src={avatar} alt={nickname} style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} />
              {show && (
                <div style={{ position: "absolute", top: "100%", right: 0 }}>
                  <div className='logined-wallet' onClick={toUser}>User Center</div>
                  <div className='logined-wallet' onClick={logOut}>Log Out</div>
                </div>
              )}
            </div>

          </div>
        </>


          : <div className='login-in' onClick={redirectToLogin}>Log in</div>
      }
    </div>
  );
})

const AboutButton = () => {
  const [isHover2, setIsHover2] = useState(false);

  const handleMouseMove = () => {
    setIsHover2(true);
  };

  const handleMouseOut = () => {
    setIsHover2(false);
  };
  const navigate = useNavigate();

  const redirectToAbout = () => {
    navigate('/about');
  }

  const classes = isHover2 ? 'about-in' : '';

  return (
    <div className={classes}
      onClick={redirectToAbout}
      onMouseMove={handleMouseMove}
      onMouseOut={handleMouseOut}
    >About Us</div>
  );
};

const Store = () => {
  const [isHover, setIsHover] = useState(true);

  const handleMouseMove = () => {
    setIsHover(false);
  };

  const handleMouseOut = () => {
    setIsHover(true);
  };

  const handleClick = () => {
    window.open('https://ayayi.shop/', "_blank");
  };

  return (
    <div className="store" onClick={handleClick}>
      <img
        width={26}
        height={26}
        src={isHover ? 'icon/store.svg' : 'icon/store2.svg'}
        alt="store"
        onMouseMove={handleMouseMove}
        onMouseOut={handleMouseOut}
      />
    </div>
  );
};

const Navbar = ({ className }) => {

  return (
    <div className={className}>
      <div className="logo">
        <Logo />
      </div>
      <div className='right'>
        <div className="about">
          <AboutButton />
        </div>
        <div className="store">
          <Store />
        </div>
        <div className="login">
          <LoginButton />
        </div>
      </div>

    </div>
  );
};

export default Navbar;