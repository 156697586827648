import { observable } from 'mobx';

class AyayiStore {
    nickname = observable.box();
    userId = observable.box();
    avatar = observable.box();
    address = observable.box();
    ensName = observable.box();
    nftImgs = observable.array();

}

const ayayiStore = new AyayiStore();

export default ayayiStore;
