
import './Login.css';
import Navbar2 from '../components/Navbar2';
import Footer2 from '../components/Footer2';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { sentCode, emaiLogin, getUserDetail } from '../api';
import SignGoogle from '../firebase/firebase.google';
import SignTwitter from '../firebase/firebase.twitter';
import SignApple from '../firebase/firebase.apple';
import { observer } from 'mobx-react-lite';
import ayayiStore from '../mobx/index'

const Login = observer(() => {

  const channel = localStorage.getItem('channel')
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [code, setCode] = useState('');
  const [isValidCode, setIsValidCode] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


  const checkEmail = (e) => {
    const email1 = e.target.value;
    const validEmail = emailRegex.test(email1);
    if (validEmail) {
      setEmail(email1);
      setIsValidEmail(true)
    } else if (email1 === '') {
      setEmail('')
      setIsValidEmail(false)
    } else {
      setEmail('errorEmail')
      setIsValidEmail(false)
    }

  };

  // check second warnning

  const checkCode = (e) => {
    const valueCode = e.target.value
    if (valueCode.length === 6 && !isNaN(valueCode)) {
      setCode(valueCode)
      setIsValidCode(true)
    } else if (valueCode === '') {
      setCode('')
      setIsValidCode(false)
    } else {
      setCode('errorCode')
      setIsValidCode(false)
    }
  }

  // send code
  const SendCode = () => {
    const [showDiv1, setShowDiv1] = useState(true);
    const [showDiv2, setShowDiv2] = useState(false);
    const [showDiv3, setShowDiv3] = useState(false);
    const [countdown, setCountdown] = useState(60);

    const CodeSend = (email) => {
      sentCode({ "email": email })
        .then(response => {
          if (response.data.code === 200) {
            console.log('sucess', response.data)
          } else {
            console.log('wrong email');
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
    useEffect(() => {
      if (showDiv2) {
        if (countdown > 0) {
          const timer = setTimeout(() => {
            setCountdown(countdown - 1);
          }, 1000); // 每秒更新倒计时

          return () => {
            clearTimeout(timer);
          };
        } else {
          setShowDiv2(false);
          setShowDiv3(true);
        }
      }
    }, [showDiv2, countdown]);

    const handleDiv1Click = () => {
      if (email !== '') {
        CodeSend(email)
        setShowDiv1(false);
        setShowDiv2(true);
      } else {
        console.log("Please enter your email")
      }

    };

    const handleDiv3Click = () => {
      CodeSend(email)
      setShowDiv3(false);
      setShowDiv2(true);
      setCountdown(60); // 重置倒计时
    };

    return (
      <div>
        {showDiv1 && (
          <div style={{ color: '#fff', cursor: 'pointer' }} onClick={handleDiv1Click}>
            Send
          </div>
        )}
        {showDiv2 && (
          <div>
            {countdown}s
          </div>
        )}
        {showDiv3 && (
          <div style={{ color: '#fff', cursor: 'pointer' }} onClick={handleDiv3Click}>
            Resend
          </div>
        )}
      </div>
    );
  };

  // click next
  const handleNextClick = () => {
    if (isValidEmail && isValidCode) {
      emaiLogin({
        "email": email,
        "code": code
      })
        .then(response => {
          // console.log('response', response.data.msg)
          if (response.data.code === 200) {
            // console.log('sucess', response.data.data)
            // 将token存储到localStorage中
            localStorage.setItem('token', response.data.data.authentication);

            getUserDetail({}).then((r) => {
              if (r.data.code === 200) {
                if (r.data.data.avatar !== '') {
                  ayayiStore.avatar.set(r.data.data.avatar)
                  localStorage.setItem('avatar', r.data.data.avatar);
                }
                ayayiStore.nickname.set(r.data.data.nickname)
                // ayayiStore.avatar.set(r.data.data.avatar)
                ayayiStore.userId.set(r.data.data.userId)

                localStorage.setItem('nickname', r.data.data.nickname);
                // localStorage.setItem('avatar', r.data.data.avatar);
                localStorage.setItem('userId', r.data.data.userId);
                navigate('/user');
                // window.location.href = '/user';
              }
            })

          } else {
            console.log(response.data);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
    // console.log(code, isValidCode, 'daddad', isValidEmail, email)
  }


  // click three button



  return (
    <div className="Login">
      <header className="login-header">
        <div className='login-navbar'>
          <Navbar2 className="navbar2" />
        </div>
      </header>
      {
        channel === 'PC' ? <>
          <div className='body-content-pc'>
            <div className='form-login-pc'>
              <div className='form1-pc'>
                <div className='title-font'>
                  <div>JOIN IN</div>
                  <div>"AYAYI®"</div>
                </div>
                <div className='title2-font'>
                  WELCOME TO AYAYI SPACE
                </div>
              </div>
              <div className='form2-pc'>
                <div className='input-address-code-pc'>
                  <div className='address-warnning-pc'>
                    <div className='input-address-pc'>
                      <input type="text" className="address" placeholder="Email Address"
                        onBlur={checkEmail} defaultValue={email}>
                      </input>
                    </div>

                    {isValidEmail || email === '' ? ''
                      :
                      <div className='warnning'>
                        <img width={13} height={13} src="icon/warnning.svg" alt="warnning" />
                        <div>Please enter the correct email format</div>
                      </div>
                    }

                  </div>
                  <div className='code-warnning-pc'>
                    <div className='input-code-pc'>
                      <input type="text" className="code" onBlur={checkCode} placeholder="Verification Code"></input>
                      <div className='resent-code'>
                        <SendCode />
                      </div>
                    </div>

                    {
                      isValidCode || code === '' ? ''
                        : <div className='warnning'>
                          <img width={13} height={13} src="icon/warnning.svg" alt="warnning" />
                          <div>Verification code input error</div>
                        </div>
                    }

                  </div>

                </div>
                <div className='next-button-pc' onClick={handleNextClick}>
                  <div>Next</div>
                </div>
                <div className='line-with-text-pc'>
                  <div className='font1'></div>
                  <div className='font2'>
                    or
                  </div>
                  <div className='font1'></div>
                </div>
                <div className='logins-pc'>
                  <SignApple />
                  <SignTwitter />
                  {/* <SignGoogle /> */}
                </div>
                <div className='belongs'>
                  By entering,you agree to AYAYI’s
                </div>
                <div className='import'>
                  <div className='policy-services'>
                    <a href="/privacy_term/AYAYI%20Website_privacy.html">Privacy Policy</a>
                  </div>
                  <div className='and'>and</div>
                  <div className='policy-services'>
                    <a href="/privacy_term/AYAYI%20Website_term.html">Terms of Service</a>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <footer>
            <div className='footerLogin'>
              <Footer2 className="footer2" />
            </div>
          </footer></>
          :
          <div className='body-content'>
            <div className='form-login'>
              <div className='form1'>
                <div className='title-font'>
                  <div>JOIN IN</div>
                  <div>"AYAYI®"</div>
                </div>
                <div className='title2-font'>
                  WELCOME TO AYAYI SPACE
                </div>
              </div>
              <div className='form2'>
                <div className='input-address-code'>
                  <div className='address-warnning'>
                    <div className='input-address'>
                      <input type="text" className="address" placeholder="Email Address"
                        onBlur={checkEmail} defaultValue={email}>
                      </input>
                    </div>

                    {isValidEmail || email === '' ? ''
                      :
                      <div className='warnning'>
                        <img width={13} height={13} src="icon/warnning.svg" alt="warnning" />
                        <div>Please enter the correct email format</div>
                      </div>
                    }

                  </div>
                  <div className='code-warnning'>
                    <div className='input-code'>
                      <input type="text" className="code" onBlur={checkCode} placeholder="Verification Code"></input>
                      <div className='resent-code'>
                        <SendCode />
                      </div>
                    </div>

                    {
                      isValidCode || code === '' ? ''
                        : <div className='warnning'>
                          <img width={13} height={13} src="icon/warnning.svg" alt="warnning" />
                          <div>Verification code input error</div>
                        </div>
                    }

                  </div>

                </div>
                <div className='next-button' onClick={handleNextClick}>
                  <div>Next</div>
                </div>
                <div className='line-with-text'>
                  <div className='font1'></div>
                  <div className='font2'>
                    or
                  </div>
                  <div className='font1'></div>
                </div>
                <div className='logins'>
                  <SignApple />
                  <SignTwitter />
                  {/* <SignGoogle /> */}
                </div>
                <div className='belongs'>
                  By entering,you agree to AYAYI’s
                </div>
                <div className='import'>
                  <div className='policy-services'>
                    <a href="/privacy_term/AYAYI%20Website_privacy.html">Privacy Policy</a>
                  </div>
                  <div className='and'>and</div>
                  <div className='policy-services'>
                    <a href="/privacy_term/AYAYI%20Website_term.html">Terms of Service</a>
                  </div>
                </div>
              </div>

            </div>
          </div>

      }

    </div>
  );
})

export default Login;
