import React, { useState, useEffect } from 'react';
import './CustomCarousel.css';

const Carousel = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    const id = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
    }, 3000);

    setIntervalId(id);

    return () => {
      clearInterval(id);
    };
  }, [images.length]);

  const handleButtonClick = (index) => {
    setCurrentImage(index);
    clearInterval(intervalId);
  };

  const handleButtonHover = (index) => {
    setCurrentImage(index);
    clearInterval(intervalId);
  };

  const handleButtonLeave = () => {
    const id = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
    }, 3000);

    setIntervalId(id);
  };

  return (
    <div className='carousel-all'>
      <div className='button-groups'>
        {images.map((_, index) => (
          <div
            className={`button-part ${index === currentImage ? 'active' : ''}`}
            key={index}
            onClick={() => handleButtonClick(index)}
            onMouseEnter={() => handleButtonHover(index)}
            onMouseLeave={handleButtonLeave}
          ></div>
        ))}
      </div>

      <div className='carousel-images'>
        <img src={images[currentImage]} alt="nft-one" />
      </div>
    </div>
  );
};

export default Carousel;
