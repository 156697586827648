import React from 'react';
import './ImgSlider.css'
import { observer } from 'mobx-react-lite';
import ayayiStore from '../mobx/index'



const ImageSlider = observer(() => {
    const images = ayayiStore.nftImgs.slice()
    return (
        <div className="gallery">
            <div className='image-gallery'>
                {images.map((nft, index) => (
                    <img key={index} src={nft} alt={`nfts ${index + 1}`} />

                ))}
            </div>
            <div className='no-more'>already over~</div>
        </div>

    );
});

export default ImageSlider;