import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// const API_URL = 'http://192.168.50.198:8094';
// const API_URL_IN = 'http://192.168.50.198:8095';
const API_URL = "https://login.ayayi.space";
const API_URL_IN = "https://api.ayayi.space";


// 国内 'http://192.168.50.198:8094';
// 国内 'http://192.168.50.198:8095';
// 国外 'https://login.ayayi.space';
// 国外 'https://api.ayayi.space';

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${token}`
    }
});
const axiosInstanceIn = axios.create({
    baseURL: API_URL_IN,
    headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${token}`
    }
});


axiosInstance.interceptors.request.use(
    (config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 1401) {
            const navigate = useNavigate();
            navigate('/login');
        }
        return Promise.reject(error);
    }
);


axiosInstanceIn.interceptors.request.use(
    (config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstanceIn.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 1401) {
            const navigate = useNavigate();
            navigate('/login');
        }
        return Promise.reject(error);
    }
);



export const sentCode = (data) => {
    return axiosInstance.post('/api/v1/email/code/send', data);
};

export const emaiLogin = (data) => {
    return axiosInstance.post('/api/v1/email/login', data);
};

// 第三方登录
export const thirdPartyLogin = (data) => {
    return axiosInstance.post('/api/v1/third-party/login', data);
};

// 个人信息
export const getUserDetail = (data) => {
    return axiosInstanceIn.post('/api/v1/user/detail', data);
};


// 个人钱包
export const getWalletBind = (data) => {
    return axiosInstanceIn.post('/api/v1/wallet/bind', data);
};

// 个人钱包
export const getAssetAllList = (data) => {
    return axiosInstanceIn.post('/api/v1/user/asset/all/list', data);
};

