// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './SwiperNFT.css';
// // 增加以下代码以使用"自动轮播"功能
import { Autoplay } from 'swiper/modules';
import 'swiper/css/autoplay';
import { useState } from 'react';
import { useEffect } from 'react';

function SwiperNFT({ nfts }) {

  const [swiper, setSwiper] = useState(null);

  const handleMouseEnter = () => {

    // console.log(swiper, Math.abs(swiper.translate), Math.abs(swiper.getTranslate()))
    if (swiper) {
      swiper.setTranslate(swiper.getTranslate());
      swiper.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    // const newSpeed =
    //   (Math.abs(
    //     Math.abs(swiper.translate) - Math.abs(swiper.getTranslate())
    //   ) /
    //     Math.abs(swiper.virtualSize / 12) * 3000);
    // console.log(newSpeed)
    if (swiper) {
      swiper.slideTo(swiper.activeIndex, 3000);
      // swiper.slideTo(swiper.activeIndex, newSpeed);
      swiper.autoplay.start();
    }
  };

  const [num, setNum] = useState(6.5);
  const channel = localStorage.getItem('channel')
  useEffect(() => {
    if (channel === 'PC') {
      setNum(6.5)
    } else {
      setNum(2)
    }
  }, [channel])

  return (
    <div className='Swiper-all'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Swiper
        className="mySwiper"
        freeMode={true}
        modules={[Autoplay]}
        spaceBetween={0}
        slidesPerView={num}
        loop={true}
        // onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => setSwiper(swiper)}


        autoplay={{
          delay: 0,
          // pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        speed={3000}
      >
        {nfts.map((nft, index) => (
          <SwiperSlide key={index}>
            <img className='Swiper-nft' src={nft} alt={`NFT ${index + 1}`} />
          </SwiperSlide>

        ))}
      </Swiper>
    </div>

  );
};


export default SwiperNFT;