import './Home.css';
import Navbar1 from '../components/Navbar1';
import Footer1 from '../components/Footer1';
import CustomCarousel from '../components/CustomCarousel';
import SwiperNFT from '../components/SwiperNFT';

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import isLoggedIn from '../utils/global';

function Home() {

  const channel = localStorage.getItem('channel')
  useEffect(() => {
    // 如果已经登录，则显示登录信息
    if (isLoggedIn()) {
      // console.log('登录信息', isLoggedIn());
    } else {
      // console.log('未登录', isLoggedIn());
    }
  }, []);

  const LearnMore = ({ className }) => {
    const navigate = useNavigate();

    const redirectToMore = () => {
      navigate('/more');
    };
    return (
      <div className={className} onClick={redirectToMore}>
        <div>LEARN MORE</div>
      </div>
    );
  };

  const Twitter = () => {
    const handleClick = () => {
      window.open('https://twitter.com/AYAYIOFFICIAL', "_blank");
    };
    return (
      <div className="theThirdButton" onClick={handleClick}>
        <div className='group-top'>
          <div className='group-top-img'>
            <img width={'100%'} height={"100%"} src="icon/Twitter.svg" alt="Twitter" />
          </div>
          <div className='group-top-font'>Twitter</div>
        </div>
        <div className='group-bottom'>Community/Lives</div>
      </div>
    );
  };

  const Instagram = () => {
    const handleClick = () => {
      window.open('https://instagram.com/ayayiofficial?igshid=YTQwZjQ0NmI0OA%3D%3D&utm_source=qr', "_blank");
    };
    return (
      <div className="theThirdButton" onClick={handleClick}>
        <div className='group-top'>
          <div className='group-top-img'>
            <img width={'100%'} height={"100%"} src="icon/Instagram.svg" alt="Instagram" />
          </div>
          <div className='group-top-font'>Instagram</div>
        </div>
        <div className='group-bottom'>Fashion/News</div>
      </div>
    );
  };

  const DISCORD = () => {
    const handleClick = () => {
      window.open('https://discord.gg/fKwg28zWNv', "_blank");
    };
    return (
      <div className="theThirdButton" onClick={handleClick}>
        <div className='group-top'>
          <div className='group-top-img'>
            <img width={'100%'} height={"100%"} src="icon/discord2.svg" alt="DISCORD" />
          </div>
          <div className='group-top-font'>Discord</div>
        </div>
        <div className='group-bottom'>Community/News</div>

      </div>
    );
  };

  const Download = () => {
    const handleClick = () => {
      window.open('https://apps.apple.com/hk/app/ayayi/id6458733124?l=en-GB', "_blank");
    };
    return (
      <div className="download-app" onClick={handleClick}>
        DOWNLOAD THE APP
      </div>
    );
  };

  const QRcode = () => {
    const [isHover3, setIsHover3] = useState(false);

    const handleMouseEnter = () => {
      setIsHover3(true);
    };

    const handleMouseLeave = () => {
      setIsHover3(false);
    };

    return (
      <div className="QRcode"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>

        {isHover3 && (
          <img width={'100%'} height={'100%'} src="other/download.png" alt="二维码" className='real-QR' />
        )}

        {!isHover3 && (
          <img
            width={'100%'} height={'100%'}
            src="icon/QRcode-min.png"
            alt="store"
          />
        )}

      </div>
    );
  };


  const shopNow = () => {
    window.open('https://ayayi.shop/', "_blank");
  };

  const nfts = [
    'nft/nfts-1.jpg',
    'nft/nfts-2.jpg',
    'nft/nfts-3.jpg',
    'nft/nfts-4.jpg',
    'nft/nfts-5.jpg',
    'nft/nfts-6.jpg',
    'nft/nfts-7.jpg',
    'nft/nfts-8.jpg',
    'nft/nfts-9.jpg',
    'nft/nfts-10.jpg',
    'nft/nfts-11.jpg',
  ];

  const images = [
    'nft/nft1.jpg',
    'nft/nft2.jpg',
    'nft/nft3.jpg',
    'nft/nft4.jpg',
    'nft/nft5.jpg',
    'nft/nft6.jpg',
    'nft/nft7.jpg',
  ];

  return (
    <div className="Home">
      <div className='Home-navbar'>
        <Navbar1 className="navbar1" />
      </div>
      <header className="Home-header">

        {
          channel === 'PC' ? <div className='Header-video-pc'><video src="video/home.mp4"
            autoPlay loop playsInline controls
            style={{
              width: '100%',
              height: '100%',
              margin: '0 auto',
              display: 'block',
            }}
          />
          </div>
            : <div className='Header-video'><video src="video/home.mp4"
              autoPlay loop playsInline muted controls
              // 
              style={{
                width: '100%',
                height: '100%',
                margin: '0 auto',
                display: 'block',
              }}
            />
            </div>
        }

      </header>
      <div className="Home-body">
        <div className='Body-page1'>
          {
            channel === 'PC' ?
              <div className='Page1-pc'>
                <div className='Body-page1-top-pc'>
                  <img width={'100%'} height={'100%'} src="other/home-img1.jpg" alt="img1" />
                </div>
                <div className='Body-page1-mid-pc'>
                  <div className='bottom-icon-pc'>
                    <div><img width={'100%'} height={'100%'} src="icon/icon1.svg" alt="icon" /></div>
                  </div>
                  <div className='bottom-fonts-pc'>
                    <div className='fonts-top-font-pc'>
                      <img width={'100%'} height={'100%'} src="icon/ayayi.svg" alt="ayayi" />
                    </div>
                    <div className='fonts-mid-pc'>
                      <div className='home-fonts-mid-mdash-pc'></div>&nbsp; A PHYGITAL BRAND
                    </div>
                    <div className='fonts-bottom-pc'>
                      Where multiple dimensions converge, dreams and reality synergize.
                    </div>
                  </div>
                </div>
                <div className='Body-page1-bottom-pc'>
                  <LearnMore className={'learn-more-pc'} />
                </div>
              </div>
              :
              <div className='Page1'>
                <div className='Body-page1-top'>
                  <img width={'100%'} height={'100%'} src="other/home-img1.jpg" alt="img1" />
                </div>
                <div className='Body-page1-mid'>
                  <div className='bottom-icon'>
                    <div><img width={'100%'} height={'100%'} src="icon/icon1.svg" alt="icon" /></div>
                  </div>
                  <div className='bottom-fonts'>
                    <div className='fonts-top'>
                      <div className='fonts-top-font'>
                        <img width={'100%'} height={'100%'} src="icon/ayayi.svg" alt="ayayi" />
                      </div>
                    </div>
                    <div className='fonts-mid'>
                      <div className='home-fonts-mid-mdash'></div>&nbsp; A PHYGITAL BRAND
                    </div>
                    <div className='fonts-bottom'>
                      Where multiple dimensions converge, dreams and reality synergize.
                    </div>

                  </div>
                </div>
                <div className='Body-page1-bottom'>
                  <LearnMore className={'learn-more'} />
                </div>
              </div>
          }
        </div>
        <div className='Body-page2'>
          {
            channel === 'PC' ?
              <div className='Page2-pc'>

                <div className='Body-page2-left-pc'>
                  <img width={'100%'} height={'100%'} src="other/home-img2.jpg" alt="img3" />
                </div>
                <div className='Body-page2-mid-pc'>
                  <div style={{ height: '18vw' }}>
                    <img width={'100%'} height={'100%'} src="icon/icon2.svg" alt="icon" />
                  </div>
                </div>
                <div className='Body-page2-right-pc'>
                  <div className='Body-page2-right-in-pc'>
                    <div className='page2-right-font1-pc'>
                      <div style={{ display: 'flex' }}><div style={{ height: '1vw', display: 'flex', alignItems: 'center' }}>「 &nbsp;</div><img width={'100%'} height={'100%'} src="icon/ayayi2.svg" alt="Logo" /><div style={{ height: '3vw', display: 'flex', alignItems: 'flex-end' }}>&nbsp;」</div></div>

                    </div>
                    <div className='page2-right-font2-pc'>
                      AYAYI® aspires to build a utopia dreamland rooted in the real world, a cultural heaven where virtuality coexists with reality. We aim to lead a new generation of young people to awaken individualism with a forward-thinking attitude, harnessing the power of free imagination to create endless wonders. In the interwoven aesthetics of virtuality and reality, we sketch a new vision of ideal living, finding a sustainable balance between dreams and reality.
                    </div>
                    <div className='page2-right-font3-pc' onClick={() => { window.open('https://discord.gg/fKwg28zWNv', "_blank"); }}>
                      <div className='page2-right-font3-in-pc'>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              : <div className='Page2'>

                <div className='Body-page2-left'>
                  <img width={'100%'} height={'100%'} src="other/home-img3.jpg" alt="img" />
                </div>

                <div className='Body-page2-right'>
                  <div className='page2-right-font1'>
                    <div style={{ display: 'flex' }}>
                      <div style={{ height: '9vw', display: 'flex', alignItems: 'center' }}>「 &nbsp;</div>
                      <img width={'100%'} height={'100%'} src="icon/ayayi2.svg" alt="Logo" />
                      <div style={{ height: '8vw', display: 'flex', alignItems: 'flex-end' }}>&nbsp;」</div>
                    </div>

                  </div>
                  <div className='page2-right-font2'>
                    AYAYI® aspires to build a utopia dreamland rooted in the real world, a cultural heaven where virtuality coexists with reality. We aim to lead a new generation of young people to awaken individualism with a forward-thinking attitude, harnessing the power of free imagination to create endless wonders. In the interwoven aesthetics of virtuality and reality, we sketch a new vision of ideal living, finding a sustainable balance between dreams and reality.
                  </div>
                  <div className='page2-right-font3' onClick={() => { window.open('https://discord.gg/fKwg28zWNv', "_blank"); }}>

                    <div className='page2-right-font3-in'>

                    </div>



                  </div>
                </div>


              </div>
          }
        </div>

        <div className='Body-page3'>
          {
            channel === 'PC' ?
              <div className='Page3-pc'>
                <div className='Body-page3-left-pc'>
                  <CustomCarousel images={images} />
                </div>
                <div className='Body-page3-right-pc'>
                  <div className='page3-right-title-pc'>
                    <div>DIMENSIONAL</div>
                    <div>EXPLORER</div>
                  </div>
                  <div className='page3-right-title2-pc'>THE 1<sup>st</sup> Collection</div>
                  <div className='page3-right-context-pc'>
                    <div>AYAYI®'s 1st Collection draws inspiration from wingsuit flying, a niche and challenging extreme sport. A century ago, marked by a touch of madness and unconventional thinking, us humans first ventured into this type of "unpowered" flight, risking life to fulfill the dream of breaking the boundaries between the sky and the ground. This longing for freedom and exploration of the unknown reflects an ultimate pursuit of dreams and fantasies.</div>

                    <div style={{
                      margin: '10px 0 0 0',
                    }}>
                      Our 1st Collection is dedicated to bridging visual and tactile sensations, centered around the theme of "DIMENSIONAL EXPLORER", thus, building a bridge between reality and dreams, encouraging young people to explore their true selves and boldly face and pursue their heart's desires.</div>
                  </div>
                  <div className='page3-right-button-pc'>
                    <div className='shopNow-pc' onClick={shopNow} ><div>SHOP NOW</div></div>
                  </div>
                </div>
              </div>
              : <div className='Page3'>
                <div className='Body-page3-left'>
                  <CustomCarousel images={images} />
                </div>
                <div className='Body-page3-right'>
                  <div className='page3-right-title'>
                    <div>DIMENSIONAL</div>
                    <div>EXPLORER</div>
                  </div>
                  <div className='page3-right-title2'>THE 1<sup>st.</sup> Collection</div>
                  <div className='page3-right-context'>
                    <div>AYAYI®'s 1st Collection draws inspiration from wingsuit flying, a niche and challenging extreme sport. A century ago, marked by a touch of madness and unconventional thinking, us humans first ventured into this type of "unpowered" flight, risking life to fulfill the dream of breaking the boundaries between the sky and the ground. This longing for freedom and exploration of the unknown reflects an ultimate pursuit of dreams and fantasies.</div>

                    <div style={{
                      margin: '10px 0 0 0',
                    }}>
                      Our 1st Collection is dedicated to bridging visual and tactile sensations, centered around the theme of "DIMENSIONAL EXPLORER", thus, building a bridge between reality and dreams, encouraging young people to explore their true selves and boldly face and pursue their heart's desires.</div>
                  </div>
                  <div className='page3-right-button'>
                    <div className='shopNow' onClick={shopNow} ><div>SHOP NOW</div></div>
                  </div>
                </div>
              </div>
          }
        </div>

        <div className='Body-page4-swiper'>
          <SwiperNFT nfts={nfts} />
        </div>

        <div className='Body-page5'>
          {
            channel === 'PC' ?
              <div className='Page5-pc'>
                <div className='Body-page5-top-pc'>

                  <div className='page5-right-title-pc'>
                    <div>Unlock an unparalleled experience by scanning the unique code that offers you</div>
                    <div>access To the AYAYI®. Explore your One-Of-A-Kind Web3 profile seamlessly </div>
                    <div>integrated with reality. Immerse yourself in a brand new lifestyle ever before.</div>
                  </div>

                  <div className='page5-right-buttons-pc'>
                    <div><QRcode /></div>
                    <div ><Download /></div>
                  </div>
                </div>

                <div className='Body-page5-bottom-pc'>
                  <img width={'100%'} height={'100%'} src="other/home-img4.jpg" alt="img4" />
                </div>
              </div>
              :
              <div className='Page5'>
                <div className='Body-page5-top'>

                  <div className='page5-right-title'>
                    Unlock an unparalleled experience by scanning the unique code that offers you
                    access To the AYAYI®. Explore your One-Of-A-Kind Web3 profile seamlessly
                    integrated with reality. Immerse yourself in a brand new lifestyle ever before.
                  </div>

                  <div className='page5-right-buttons'>
                    <div><QRcode /></div>
                    <div ><Download /></div>
                  </div>
                </div>

                <div className='Body-page5-bottom'>
                  <img width={'100%'} height={'100%'} src="other/home-img5.jpg" alt="img4" />
                </div>
              </div>
          }
        </div>
        <div className='Body-page6'>
          {
            channel === 'PC' ?
              <div className='Page6-pc'>
                <div className='Body-page6-top-pc'>
                  <div>JOIN THE COMMUNITY</div>
                </div>
                <div className='Body-page6-mid-pc'>
                  <div>You can find AYAYI® on the following platforms to discover and learn more </div>
                  <div>about the latest updates on AYAYI®</div>
                </div>
                <div className='Body-page6-mid2-pc'>
                  <Twitter />
                  <Instagram />
                  <DISCORD />
                </div>
              </div>
              :
              <div className='Page6'>
                <div className='Body-page6-top'>
                  <div>JOIN THE COMMUNITY</div>
                </div>
                <div className='Body-page6-mid'>
                  <div>You can find AYAYI on the following platforms to discover and</div>
                  <div>learn more about the latest updates on AYAYI</div>
                </div>
                <div className='Body-page6-mid2'>
                  <Twitter />
                  <Instagram />
                  <DISCORD />
                </div>
              </div>
          }
        </div>
        <div className='Body-page7'>
          {
            channel === 'PC' ?
              <div className='Page7-pc'>
                <div className='Body-page7-top-pc'>
                  <div>Get In Touch </div>
                </div>
                <div className='Body-page7-mid-pc'>
                  <div>If You Wish To Establish Business-Related Contact Or Provide Feedback On Product-Related Issues, </div>
                  <div>You Can Send An
                    Email To Our Address:
                    <a href='mailto:contact@ayayi.space'>contact@ayayi.space</a>
                  </div>
                  <div>Or Reach Out To Us Through Direct Messages On Our Social Media.</div>
                </div>
              </div>
              :
              <div className='Page7'>
                <div className='Body-page7-top'>
                  <div>Get In Touch </div>
                </div>
                <div className='Body-page7-mid'>
                  <div>If You Wish To Establish Business-Related Contact Or Provide Feedback On Product-Related Issues, You Can Send An
                    Email To Our Address:
                    <a href='mailto:contact@ayayi.space'>contact@ayayi.space</a>
                  </div>
                  <div>Or Reach Out To Us Through Direct Messages On Our Social Media.</div>
                </div>
              </div>
          }
        </div>
      </div>
      <footer>
        <div className="Home-footer">
          <Footer1 className="footer1" />
        </div>
      </footer>
    </div>

  );
}

export default Home;
